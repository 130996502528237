<template>
   <v-card flat tile class="fill-height">
      <v-card-title class="blue-grey--text text--lighten-2 pb-0 px-5">
        <span class="text-h6 pt-3 font-weight-bold">Estatus</span>
      </v-card-title>
      <v-card-text class="pt-0 px-5">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-switch
              v-model="statusClients.verde"
              color="info"
              hide-details
            >
              <template v-slot:label>
                <span class="caption font-weight-medium mx-4">
                  <v-icon
                    color="success"
                    v-text="'mdi-map-marker'"
                  ></v-icon>
                  Sin Cuenta por cobrar
                </span>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-switch
              v-model="statusClients.amarillo"
              color="info"
              hide-details
            >
              <template v-slot:label>
                <span class="caption font-weight-medium mx-4">
                  <v-icon
                    color="warning"
                    v-text="'mdi-map-marker'"
                  ></v-icon>
                  Cuenta por cobrar pendiente
                </span>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="statusClients.rojo"
              color="info"
              hide-details
            >
              <template v-slot:label>
                <span class="caption font-weight-medium mx-4">
                  <v-icon
                    color="red"
                    v-text="'mdi-map-marker'"
                  ></v-icon>
                  Cuenta por cobrar Vencida
                </span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-card-text>
   </v-card>
</template>

<script>
export default {
  name: 'ListaEstatus',
  props:{
    statusClients:{
      type: Object,
      default: () => ({})
    }
  },
  methods:{
    close(){
      this.$emit('close');
    }
  }
}
</script>

<style>

</style>
